import React from "react";

import blogImg1 from "../extra-images/blog-grid-img1.jpg";
import blogImg2 from "../extra-images/blog-grid-img2.jpg";
import blogImg3 from "../extra-images/blog-grid-img3.jpg";
import blogImg4 from "../extra-images/blog-grid-img4.jpg";
import blogImg5 from "../extra-images/blog-grid-img5.jpg";
import blogImg6 from "../extra-images/blog-grid-img6.jpg";
import blogImg7 from "../extra-images/blog-grid-img7.jpg";
import blogImg8 from "../extra-images/blog-grid-img8.jpg";
import blogImg9 from "../extra-images/blog-grid-img9.jpg";

const Blog = () => {
  return (
    <div class="education-main-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="education-blog education-blog-grid">
              <ul class="row">
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg1} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-blog-grid-text">
                    <h5>
                      <a href="/blog-detail">Seven UK business schools </a>
                    </h5>
                    <ul class="education-grid-option">
                      <li>21 Aug , 2017</li>
                      <li>
                        <a href="404.html">George Hamilton</a>
                      </li>
                    </ul>
                    <p>
                      Lorem ipsum dolor sit amet, consecctur adip iscing elit. Sed et elementum
                      nlla, eu place rat felis. Etiam tincidunt.
                    </p>
                    <a href="/blog-detail" class="education-classic-btn">
                      Read More
                    </a>
                    <ul class="education-grid-comments">
                      <li>
                        <a href="404.html">
                          <i class="fa fa-eye"></i> <br /> 188
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-heart-o"></i> <br /> 211
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-comments-o"></i> <br /> 132
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg2} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-blog-grid-text">
                    <h5>
                      <a href="/blog-detail">Seven UK business schools </a>
                    </h5>
                    <ul class="education-grid-option">
                      <li>21 Aug , 2017</li>
                      <li>
                        <a href="404.html">George Hamilton</a>
                      </li>
                    </ul>
                    <p>
                      Lorem ipsum dolor sit amet, consecctur adip iscing elit. Sed et elementum
                      nlla, eu place rat felis. Etiam tincidunt.
                    </p>
                    <a href="/blog-detail" class="education-classic-btn">
                      Read More
                    </a>
                    <ul class="education-grid-comments">
                      <li>
                        <a href="404.html">
                          <i class="fa fa-eye"></i> <br /> 188
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-heart-o"></i> <br /> 211
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-comments-o"></i> <br /> 132
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg3} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-blog-grid-text">
                    <h5>
                      <a href="/blog-detail">Seven UK business schools </a>
                    </h5>
                    <ul class="education-grid-option">
                      <li>21 Aug , 2017</li>
                      <li>
                        <a href="404.html">George Hamilton</a>
                      </li>
                    </ul>
                    <p>
                      Lorem ipsum dolor sit amet, consecctur adip iscing elit. Sed et elementum
                      nlla, eu place rat felis. Etiam tincidunt.
                    </p>
                    <a href="/blog-detail" class="education-classic-btn">
                      Read More
                    </a>
                    <ul class="education-grid-comments">
                      <li>
                        <a href="404.html">
                          <i class="fa fa-eye"></i> <br /> 188
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-heart-o"></i> <br /> 211
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-comments-o"></i> <br /> 132
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg4} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-blog-grid-text">
                    <h5>
                      <a href="/blog-detail">Seven UK business schools </a>
                    </h5>
                    <ul class="education-grid-option">
                      <li>21 Aug , 2017</li>
                      <li>
                        <a href="404.html">George Hamilton</a>
                      </li>
                    </ul>
                    <p>
                      Lorem ipsum dolor sit amet, consecctur adip iscing elit. Sed et elementum
                      nlla, eu place rat felis. Etiam tincidunt.
                    </p>
                    <a href="/blog-detail" class="education-classic-btn">
                      Read More
                    </a>
                    <ul class="education-grid-comments">
                      <li>
                        <a href="404.html">
                          <i class="fa fa-eye"></i> <br /> 188
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-heart-o"></i> <br /> 211
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-comments-o"></i> <br /> 132
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg5} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-blog-grid-text">
                    <h5>
                      <a href="/blog-detail">Seven UK business schools </a>
                    </h5>
                    <ul class="education-grid-option">
                      <li>21 Aug , 2017</li>
                      <li>
                        <a href="404.html">George Hamilton</a>
                      </li>
                    </ul>
                    <p>
                      Lorem ipsum dolor sit amet, consecctur adip iscing elit. Sed et elementum
                      nlla, eu place rat felis. Etiam tincidunt.
                    </p>
                    <a href="/blog-detail" class="education-classic-btn">
                      Read More
                    </a>
                    <ul class="education-grid-comments">
                      <li>
                        <a href="404.html">
                          <i class="fa fa-eye"></i> <br /> 188
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-heart-o"></i> <br /> 211
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-comments-o"></i> <br /> 132
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg6} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-blog-grid-text">
                    <h5>
                      <a href="/blog-detail">Seven UK business schools </a>
                    </h5>
                    <ul class="education-grid-option">
                      <li>21 Aug , 2017</li>
                      <li>
                        <a href="404.html">George Hamilton</a>
                      </li>
                    </ul>
                    <p>
                      Lorem ipsum dolor sit amet, consecctur adip iscing elit. Sed et elementum
                      nlla, eu place rat felis. Etiam tincidunt.
                    </p>
                    <a href="/blog-detail" class="education-classic-btn">
                      Read More
                    </a>
                    <ul class="education-grid-comments">
                      <li>
                        <a href="404.html">
                          <i class="fa fa-eye"></i> <br /> 188
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-heart-o"></i> <br /> 211
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-comments-o"></i> <br /> 132
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg7} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-blog-grid-text">
                    <h5>
                      <a href="/blog-detail">Seven UK business schools </a>
                    </h5>
                    <ul class="education-grid-option">
                      <li>21 Aug , 2017</li>
                      <li>
                        <a href="404.html">George Hamilton</a>
                      </li>
                    </ul>
                    <p>
                      Lorem ipsum dolor sit amet, consecctur adip iscing elit. Sed et elementum
                      nlla, eu place rat felis. Etiam tincidunt.
                    </p>
                    <a href="/blog-detail" class="education-classic-btn">
                      Read More
                    </a>
                    <ul class="education-grid-comments">
                      <li>
                        <a href="404.html">
                          <i class="fa fa-eye"></i> <br /> 188
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-heart-o"></i> <br /> 211
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-comments-o"></i> <br /> 132
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg8} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-blog-grid-text">
                    <h5>
                      <a href="/blog-detail">Seven UK business schools </a>
                    </h5>
                    <ul class="education-grid-option">
                      <li>21 Aug , 2017</li>
                      <li>
                        <a href="404.html">George Hamilton</a>
                      </li>
                    </ul>
                    <p>
                      Lorem ipsum dolor sit amet, consecctur adip iscing elit. Sed et elementum
                      nlla, eu place rat felis. Etiam tincidunt.
                    </p>
                    <a href="/blog-detail" class="education-classic-btn">
                      Read More
                    </a>
                    <ul class="education-grid-comments">
                      <li>
                        <a href="404.html">
                          <i class="fa fa-eye"></i> <br /> 188
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-heart-o"></i> <br /> 211
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-comments-o"></i> <br /> 132
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg9} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-blog-grid-text">
                    <h5>
                      <a href="/blog-detail">Seven UK business schools </a>
                    </h5>
                    <ul class="education-grid-option">
                      <li>21 Aug , 2017</li>
                      <li>
                        <a href="404.html">George Hamilton</a>
                      </li>
                    </ul>
                    <p>
                      Lorem ipsum dolor sit amet, consecctur adip iscing elit. Sed et elementum
                      nlla, eu place rat felis. Etiam tincidunt.
                    </p>
                    <a href="/blog-detail" class="education-classic-btn">
                      Read More
                    </a>
                    <ul class="education-grid-comments">
                      <li>
                        <a href="404.html">
                          <i class="fa fa-eye"></i> <br /> 188
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-heart-o"></i> <br /> 211
                        </a>
                      </li>
                      <li>
                        <a href="404.html">
                          <i class="fa fa-comments-o"></i> <br /> 132
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>

            <div class="education-pagination">
              <ul class="page-numbers">
                <li>
                  <a class="previous page-numbers" href="404.html">
                    <span aria-label="Next">
                      <i class="fa fa-angle-left"></i>
                    </span>
                  </a>
                </li>
                <li>
                  <span class="page-numbers current">1</span>
                </li>
                <li>
                  <a class="page-numbers" href="404.html">
                    2
                  </a>
                </li>
                <li>
                  <a class="page-numbers" href="404.html">
                    3
                  </a>
                </li>
                <li>
                  <a class="page-numbers" href="404.html">
                    4
                  </a>
                </li>
                <li>
                  <a class="next page-numbers" href="404.html">
                    <span aria-label="Next">
                      <i class="fa fa-angle-right"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
