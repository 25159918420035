import React from "react";

import courseThumbImg from "../extra-images/course-thumb-img.jpg";
import detailOptionImg from "../extra-images/detail-option-img.jpg";
import outlineImg1 from "../extra-images/outline-img1.jpg";
import outlineImg2 from "../extra-images/outline-img2.jpg";
import outlineImg3 from "../extra-images/outline-img3.jpg";
import sideFeaturedImg1 from "../extra-images/side-featured-img1.jpg";
import sideFeaturedImg2 from "../extra-images/side-featured-img2.jpg";
import sideFeaturedImg3 from "../extra-images/side-featured-img3.jpg";

const CourseDetails = () => {
  return (
    <div class="education-main-section">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <figure class="education-course-thumb">
              <img src={courseThumbImg} alt="Guiguidanceplusni" />
              <figcaption>
                <h2>Introduction LearnPress – LMS plugin</h2>
                <div class="star-rating">
                  <span class="star-rating-box width70"></span>
                </div>
                <small>( 4 Reviews )</small>
              </figcaption>
            </figure>
            <div class="education-rich-editor">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac malesuada ante.
                Curabitur lacinia diam tempus tempor consectet Sed vitae dignissim purus, eget
                aliquam libero. Duis et arcu a erat venenatis ornare eget nec urna. Nulla volutpat
                luctus venen Aliquam tellus dui, luctus nec ornare at, aliquet ac nulla. Quisque vit
                ae feugiat eros. Pellentesque tempus tortor nec tellus el.
              </p>
              <p>
                Sed gravida, urna quis tempus sollicitudin, tellus urna suscipit nisl, id rhoncus
                ligula elit condimentum odio. Su spendisse lacinia, risus et porta dignissim, elit
                tellus iaculis tellus, eget efficitur elit magna eu orci. Phasellus tempor
                consectetur magna, at efficit est malesuada ac.
              </p>
              <blockquote>
                <i class="fa fa-quote-right"></i>
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                Curae id efficitur condimentum, dui nisl ullamcorper diam, at molestie nulla erat
                gna egestas tempor Lorem ipsum dolor sit.
              </blockquote>
              <p>
                Sed gravida, urna quis tempus sollicitudin, tellus urna suscipit nisl, id rhoncus
                ligula elit condimentum odio. Su risus et porta dignissim, elit tellus iaculis
                tellus, eget efficitur elit magna eu orci. Phasellus tempor consesac est malesuada
                ac. Phasellus non ipsum diam.
              </p>
            </div>
            <div class="education-post-tags">
              <div class="education-tags">
                <span>Tags:</span>
                <a href="/">Course</a>
                <a href="/">Learn</a>
                <a href="/">Education</a>
              </div>
              <a href="/">Enroll For Course</a>
            </div>
            <div class="education-section-heading">
              <h2>Course Features</h2>
            </div>
            <div class="education-course-features">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et elementum nulla, eu
                placerat felis. Etiam tincidunt orci lacus id varius dolor fermentum sit amet.{" "}
              </p>
              <ul class="education-course-detail-option">
                <li>
                  <i class="fa fa-calendar-o"></i>
                  <h6>Starts From:</h6>
                  <span>21 August</span>
                </li>
                <li>
                  <i class="fa fa-clone"></i>
                  <h6>Lectures:</h6>
                  <span>08</span>
                </li>
                <li>
                  <i class="fa fa-clock-o"></i>
                  <h6>Duration:</h6>
                  <span>4 Hours</span>
                </li>
                <li>
                  <i class="fa fa-user-plus"></i>
                  <h6>Available Seats:</h6>
                  <span>23</span>
                </li>
                <li>
                  <i class="fa fa-language"></i>
                  <h6>Languages:</h6>
                  <span>English</span>
                </li>
                <li>
                  <i class="fa fa-level-up"></i>
                  <h6>Skill Level:</h6>
                  <span>All Level</span>
                </li>
                <li>
                  <i class="fa fa-check-square-o"></i>
                  <h6>Assesment:</h6>
                  <span>Self</span>
                </li>
              </ul>
            </div>
            <div class="education-section-heading">
              <h2>Course Certification</h2>
            </div>
            <div class="education-course-features">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et elementum nulla, eu
                placerat felis. Etiam tincidunt orci lacus, id varius dolor fermentum sit amet.
                Morbi consectetur ante odio, at vestibulum nibh fringilla id. In porttitor nunc at
                leo po rttitor mattis. Nulla malesuada sem eget dui facilisis, sed vulputate sem
                eleifend.
              </p>
              <ul class="education-list-style-one">
                <li>Lens of landscape theory and design practice</li>
                <li>Research the conditions of urbanism</li>
                <li>Employs advanced design techniques </li>
              </ul>
            </div>
            <div class="education-section-heading">
              <h2>Course Outline</h2>
            </div>
            <div class="education-course-outline">
              <ul class="heading">
                <li>Subject Title</li>
                <li>Subject Instructor</li>
                <li>File Type</li>
              </ul>
              <ul>
                <li>
                  <small>01</small>
                  <a class="preview" href="/">
                    Preview
                  </a>
                  <section>
                    <h6>Welcome to the Course!</h6>
                    <ul class="education-course-grid-option">
                      <li>
                        <i class="fa fa-calendar-o"></i> 21 / 08 / 2017
                      </li>
                      <li>
                        <i class="fa fa-clock-o"></i> 09:00 am - 11:30 am
                      </li>
                    </ul>
                  </section>
                </li>
                <li>
                  <figure>
                    <img src={outlineImg1} alt="Guiguidanceplusni" />
                  </figure>
                  <section>
                    <h6>Prof George Hamilton</h6>
                    <p>Modern Languages & Linguistics</p>
                  </section>
                </li>
                <li>
                  <a href="/" class="education-course-file">
                    <i class="fa fa-file-pdf-o"></i>
                    <span>PDF File</span>
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <small>02</small>
                  <a class="preview" href="/">
                    Preview
                  </a>
                  <section>
                    <h6>Welcome to the Course!</h6>
                    <ul class="education-course-grid-option">
                      <li>
                        <i class="fa fa-calendar-o"></i> 21 / 08 / 2017
                      </li>
                      <li>
                        <i class="fa fa-clock-o"></i> 09:00 am - 11:30 am
                      </li>
                    </ul>
                  </section>
                </li>
                <li>
                  <figure>
                    <img src={outlineImg2} alt="Guiguidanceplusni" />
                  </figure>
                  <section>
                    <h6>Prof George Hamilton</h6>
                    <p>Modern Languages & Linguistics</p>
                  </section>
                </li>
                <li>
                  <a href="/" class="education-course-file">
                    <i class="fa fa-play-circle-o"></i>
                    <span>play</span>
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <small>03</small>
                  <a class="preview" href="/">
                    Preview
                  </a>
                  <section>
                    <h6>Welcome to the Course!</h6>
                    <ul class="education-course-grid-option">
                      <li>
                        <i class="fa fa-calendar-o"></i> 21 / 08 / 2017
                      </li>
                      <li>
                        <i class="fa fa-clock-o"></i> 09:00 am - 11:30 am
                      </li>
                    </ul>
                  </section>
                </li>
                <li>
                  <figure>
                    <img src={outlineImg3} alt="Guiguidanceplusni" />
                  </figure>
                  <section>
                    <h6>Prof George Hamilton</h6>
                    <p>Modern Languages & Linguistics</p>
                  </section>
                </li>
                <li>
                  <a href="/" class="education-course-file">
                    <i class="fa fa-file-pdf-o"></i>
                    <span>PDF File</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <aside class="col-md-3 education-sidebar">
            <div class="education-widget-heading">
              <h2>Featured Courses</h2>
            </div>
            <div class="widget widget_featured_courses">
              <ul>
                <li>
                  <figure>
                    <a href="course-detail.html">
                      <img src={sideFeaturedImg1} alt="Guiguidanceplusni" />
                      <i class="fa fa-link"></i>
                    </a>
                    <figcaption>
                      <h6>
                        <a href="course-detail.html">Lorem ipsum dolor si tamet cons</a>
                      </h6>
                      <a href="course-detail.html">
                        <i class="fa fa-user"></i>Prof John Martyn
                      </a>
                      <div class="star-rating">
                        <span class="star-rating-box width54"></span>
                      </div>
                      <small>( 12 Reviews )</small>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a href="course-detail.html">
                      <img src={sideFeaturedImg2} alt="Guiguidanceplusni" />
                      <i class="fa fa-link"></i>
                    </a>
                    <figcaption>
                      <h6>
                        <a href="course-detail.html">Lorem ipsum dolor si tamet cons</a>
                      </h6>
                      <a href="course-detail.html">
                        <i class="fa fa-user"></i>Prof John Martyn
                      </a>
                      <div class="star-rating">
                        <span class="star-rating-box width54"></span>
                      </div>
                      <small>( 12 Reviews )</small>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a href="course-detail.html">
                      <img src={sideFeaturedImg3} alt="Guiguidanceplusni" />
                      <i class="fa fa-link"></i>
                    </a>
                    <figcaption>
                      <h6>
                        <a href="course-detail.html">Lorem ipsum dolor si tamet cons</a>
                      </h6>
                      <a href="course-detail.html">
                        <i class="fa fa-user"></i>Prof John Martyn
                      </a>
                      <div class="star-rating">
                        <span class="star-rating-box width54"></span>
                      </div>
                      <small>( 12 Reviews )</small>
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default CourseDetails;
