import React, { useState } from "react";
// import Slider from "react-slick";
import { Outlet, useLocation } from "react-router-dom";

import { routes } from "../PageRoutes.js";

import logo from "../images/guidanceplusni.png";
import banner1 from "../extra-images/banner-1.jpg";
import bannerThumb1 from "../extra-images/banner-thumb-1.png";
// import bannerThumb2 from "../extra-images/banner-thumb-2.png";
import footerLogo from "../images/guidanceplusni.png";
import partnerImg1 from "../extra-images/Institutions-1.png";
import partnerImg2 from "../extra-images/Institutions-2.png";
import partnerImg3 from "../extra-images/Institutions-3.png";
import partnerImg4 from "../extra-images/Institutions-4.png";
import partnerImg5 from "../extra-images/Institutions-5.png";
import partnerImg6 from "../extra-images/Institutions-6.png";
import partnerImg7 from "../extra-images/Institutions-7.png";
import partnerImg8 from "../extra-images/Institutions-8.png";
import newslatter from "../extra-images/news-latter-img.jpg";
import featuredCourse1 from "../extra-images/widget-featured-courses-1.jpg";
import featuredCourse2 from "../extra-images/widget-featured-courses-2.jpg";
import featuredCourse3 from "../extra-images/widget-featured-courses-3.jpg";

const Layout = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  const pageRouteFound = routes.find((r) => `/${r.path}` === location.pathname);
  const [showHideNav1, setShowHideNav1] = useState(false);
  const [showHideNav2, setShowHideNav2] = useState(false);
  // console.log("MYLOG pageRouteFound", pageRouteFound);

  return (
    <>
      <div class="education-main-wrapper">
        <header id="education-header" class="education-header-one">
          <div class="education-top-strip education-bgcolor">
            <div class="container">
              <div class="row">
                <aside class="col-md-6">
                  <ul class="education-userinfo">
                    <li>
                      <i class="fa fa-clock-o"></i> Mon to Sat (9-30am - 5-30pm)
                    </li>
                    <li>
                      <a href="tel:+917736988854">
                        <i class="fa fa-phone"></i> (+91) 77369 88854
                      </a>
                    </li>
                  </ul>
                </aside>
                <aside class="col-md-6">
                  <ul class="education-user-section">
                    <li>
                      <a href="mailto:admission@guidanceplusni.com">
                        <i class="fa fa-envelope"></i> admission@guidanceplusni.com
                      </a>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>

          <div class="education-main-header">
            <div class="container">
              <div class="row">
                <aside class="col-md-5">
                  <nav class="navbar navbar-default">
                    <div class="navbar-header">
                      <button
                        type="button"
                        class="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#navbar-collapse-1"
                        aria-expanded="true"
                        onClick={() => setShowHideNav1(!showHideNav1)}
                      >
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                      </button>
                    </div>
                    <div
                      class={`collapse navbar-collapse ${showHideNav1 ? "in" : ""}`}
                      id="navbar-collapse-1"
                    >
                      <ul class="nav navbar-nav">
                        <li class={isHome ? "active" : ""}>
                          <a href="/">Home</a>
                        </li>
                        <li class={pageRouteFound?.path === "about-us" ? "active" : ""}>
                          <a href="/about-us">About us</a>
                        </li>
                        <li class={pageRouteFound?.path === "study-abroad-courses" ? "active" : ""}>
                          <a href="/study-abroad-courses">Courses</a>
                        </li>
                        <li>
                          <a href="#">Country</a>
                          <ul class="education-dropdown-menu">
                            <li>
                              <a href="/study-in-canada">Canada</a>
                            </li>
                            <li>
                              <a href="/study-in-uk-agency">UK</a>
                            </li>
                            <li>
                              <a href="/study-in-france">France</a>
                            </li>
                            <li>
                              <a href="/study-in-germany">Germany</a>
                            </li>
                            <li>
                              <a href="/study-in-dubai">Dubai</a>
                            </li>
                            <li>
                              <a href="/study-abroad-in-ireland">Ireland</a>
                            </li>
                            <li>
                              <a href="/malaysia-study-abroad-agency">Malaysia</a>
                            </li>
                            <li>
                              <a href="/study-in-usa">USA</a>
                            </li>
                            <li>
                              <a href="/study-abroad-in-australia">Australia</a>
                            </li>
                            <li>
                              <a href="/study-in-singapore">Singapore</a>
                            </li>
                            <li>
                              <a href="/study-in-new-zealand">New Zealand</a>
                            </li>
                            <li>
                              <a href="/deciding-to-study-in-sweden">Sweden</a>
                            </li>
                            <li>
                              <a href="/study-in-finland">Finland</a>
                            </li>
                            <li>
                              <a href="/study-in-denmark">Denmark</a>
                            </li>
                            <li>
                              <a href="/study-in-malta">Malta</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </aside>
                <aside class="col-md-2">
                  <a href="/" class="education-logo">
                    <img src={logo} alt="Guiguidanceplusni" />
                  </a>
                </aside>
                <aside class="col-md-5">
                  <nav class="navbar navbar-default">
                    <div class="navbar-header">
                      <button
                        type="button"
                        class="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#navbar-collapse-2"
                        aria-expanded="true"
                        onClick={() => setShowHideNav2(!showHideNav2)}
                      >
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                      </button>
                    </div>
                    <div
                      class={`collapse navbar-collapse ${showHideNav2 ? "in" : ""}`}
                      id="navbar-collapse-2"
                    >
                      <ul class="nav navbar-nav">
                        <li>
                          <a href="#">Services</a>
                          <ul class="education-dropdown-menu">
                            <li>
                              <a href="/">World wide university admission</a>
                            </li>
                            <li>
                              <a href="/">Skilled worker placement</a>
                              <ul class="education-dropdown-menu">
                                <li>
                                  <a href="/">Employer</a>
                                </li>
                                <li>
                                  <a href="/">Jobseeker</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="/">Visa Application Assistance</a>
                            </li>
                            <li>
                              <a href="/">Overseas NMC Registration Assistance</a>
                            </li>
                            <li>
                              <a href="/">OCI Application Assistance</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="/blog">Blog</a>
                        </li>
                        <li>
                          <a href="/gallery">Gallery</a>
                        </li>
                        <li>
                          <a href="/contact-us">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </aside>
              </div>
            </div>
          </div>
        </header>

        {isHome ? (
          <div class="education-banner">
            <div class="education-banner-one">
              <div class="education-banner-one-layer">
                <img src={banner1} alt="Guiguidanceplusni" />
                <div class="education-banner-caption">
                  <span class="education-bgcolor">REALIZE YOUR TRUE POTENTIAL WITH THE</span>
                  <br />
                  <span class="education-bgcolor banner-title">Best Study Abroad Consultant</span>
                </div>
                <div class="education-banner-thumb">
                  <img src={bannerThumb1} alt="Guiguidanceplusni" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          pageRouteFound && (
            <div class="education-subheader">
              <span class="subheader-transparent"></span>
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="education-subheader-title">
                      <h1>{pageRouteFound?.element?.props?.pageTitle ?? ""}</h1>
                    </div>
                    <ul class="education-breadcrumb">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <i class="fa fa-long-arrow-right"></i>
                      </li>
                      <li class="active">{pageRouteFound?.element?.props?.pageTitle ?? ""}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        )}

        {isHome || pageRouteFound ? (
          <div class="education-main-content">
            <Outlet />

            <div class="education-main-section education-partner-full">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="education-fancy-title">
                      <i class="fa fa-graduation-cap"></i>
                      <h2>Premium Institutions</h2>
                    </div>
                    <div class="education-partner">
                      <ul>
                        <li>
                          <a href="/">
                            <img src={partnerImg1} alt="Guiguidanceplusni" />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img src={partnerImg2} alt="Guiguidanceplusni" />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img src={partnerImg3} alt="Guiguidanceplusni" />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img src={partnerImg4} alt="Guiguidanceplusni" />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img src={partnerImg5} alt="Guiguidanceplusni" />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img src={partnerImg6} alt="Guiguidanceplusni" />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img src={partnerImg7} alt="Guiguidanceplusni" />
                          </a>
                        </li>
                        <li>
                          <a href="/">
                            <img src={partnerImg8} alt="Guiguidanceplusni" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="education-main-section">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="education-subscribe-newsletter">
                      <img src={newslatter} alt="Guiguidanceplusni" />
                      <div class="education-newslatter-text">
                        <h2>Subscribe To Our Newsletter</h2>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat
                          ipsum maximus malesuada. Donec non ex faucibus, fringilla tellus eget,
                          maximus arcu. Duis et eros nisl.
                        </p>
                        <form>
                          <input
                            type="text"
                            value="Type Here"
                            onblur="if(this.value == '') { this.value ='Type Here'; }"
                            onfocus="if(this.value =='Type Here') { this.value = ''; }"
                          />
                          <label>
                            <input type="submit" value="Subscribe Now" />
                          </label>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Outlet />
        )}

        <footer id="education-footer" class="education-footer-one">
          <span class="education-footer-pattren"></span>

          <div class="education-footer-widget">
            <div class="container">
              <div class="row">
                <aside class="col-md-4 widget widget_contact_info">
                  <a href="/" class="education-footer-logo">
                    <img src={footerLogo} alt="Guiguidanceplusni" />
                  </a>
                  <ul>
                    <li>
                      <i class="education-color fa fa-home"></i> Bay Pride Mall ,Marine Drive,
                      Kochi, Kerala 682031
                    </li>
                    <li>
                      <i class="education-color fa fa-home"></i> Door No. 39/2158-1, Moolepat
                      (Kollannur Plaza) MG Road, Ernakulam, PIN: 682016.
                    </li>
                    <li>
                      <i class="education-color fa fa-home"></i> Market Rd, near Private bus stand,
                      Koothattukulam, Kerala 686662
                    </li>
                  </ul>
                </aside>

                <aside class="col-md-4 widget widget_contact_info">
                  <div class="education-footer-title">
                    <h4>Useful Links</h4>
                  </div>
                  <ul>
                    <li>
                      <i class="education-color fa fa-phone"></i> +91 7736988869
                    </li>
                    <li>
                      <i class="education-color fa fa-phone"></i> +91 7736988851
                    </li>
                    <li>
                      <i class="education-color fa fa-phone"></i> +91 7736911154
                    </li>
                    <li>
                      <i class="education-color fa fa-phone"></i> +91 04844300501
                    </li>
                    <li>
                      <i class="education-color fa fa-envelope"></i>
                      <a href="mailto:admission@guidanceplusni.com">admission@guidanceplusni.com</a>
                    </li>
                  </ul>
                </aside>

                <aside class="col-md-4 widget widget_featured_courses">
                  <div class="education-footer-title">
                    <h4>Featured Courses</h4>
                  </div>
                  <ul>
                    <li>
                      <figure>
                        <a href="/">
                          <img src={featuredCourse1} alt="Guiguidanceplusni" />
                        </a>
                        <figcaption>
                          <h6>
                            <a href="/">Lorem ipsum dolor sit amet, cons scing elit. Praesent.</a>
                          </h6>
                          <div class="star-rating">
                            <span class="star-rating-box" style={{ width: "54%" }}></span>
                          </div>
                          <small>( 12 Reviews )</small>
                        </figcaption>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <a href="/">
                          <img src={featuredCourse2} alt="Guiguidanceplusni" />
                        </a>
                        <figcaption>
                          <h6>
                            <a href="/">Lorem ipsum dolor sit amet, cons scing elit. Praesent.</a>
                          </h6>
                          <div class="star-rating">
                            <span class="star-rating-box" style={{ width: "34%" }}></span>
                          </div>
                          <small>( 12 Reviews )</small>
                        </figcaption>
                      </figure>
                    </li>
                    <li>
                      <figure>
                        <a href="/">
                          <img src={featuredCourse3} alt="Guiguidanceplusni" />
                        </a>
                        <figcaption>
                          <h6>
                            <a href="/">Lorem ipsum dolor sit amet, cons scing elit. Praesent.</a>
                          </h6>
                          <div class="star-rating">
                            <span class="star-rating-box" style={{ width: "85%" }}></span>
                          </div>
                          <small>( 12 Reviews )</small>
                        </figcaption>
                      </figure>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="education-copyright">
                  <p>
                    All Right Reserved - by <a href="/">Guidance Plus</a>
                  </p>
                  <ul>
                    <li>
                      <a href="/">Privacy</a>
                    </li>
                    <li>
                      <a href="/">Terms</a>
                    </li>
                    <li>
                      <a href="/">Sitemap</a>
                    </li>
                    <li>
                      <a href="/">Purchase</a>
                    </li>
                  </ul>
                  <a href="#" class="education-back-top">
                    <i class="fa fa-angle-up"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <div class="clearfix"></div>
      </div>
    </>
  );
};

export default Layout;
