import React from "react";

import coursesGrid1 from "../extra-images/MBA-in-USA.jpg";
import coursesGrid2 from "../extra-images/Banking-and-Finance.jpg";
import coursesGrid3 from "../extra-images/Engineering.jpg";
import coursesGrid4 from "../extra-images/Computer-Science.jpg";
import coursesGrid5 from "../extra-images/Mass-Communication.jpg";
import coursesGrid6 from "../extra-images/Hotel-Management.jpg";
import coursesGrid7 from "../extra-images/event-management-courses-abroad.jpg";
import coursesGrid8 from "../extra-images/data-science-courses-abroad.jpg";
import coursesGrid9 from "../extra-images/Health-Science-Courses-Abroad.jpg";
import coursesGrid10 from "../extra-images/MBA-in-Ireland.jpg";
import coursesGrid11 from "../extra-images/Artificial-Intelligence-Courses-Abroad.jpg";
import coursesGrid12 from "../extra-images/Social-Work-Courses-Abroad.jpg";

const Courses = () => {
  const courseList = [
    { name: "MBA in Abroad", image: coursesGrid1, link: "/study-abroad-courses/mba-in-abroad" },
    {
      name: "Banking and Finance Courses Abroad",
      image: coursesGrid2,
      link: "/study-abroad-courses/banking-and-finance-courses-abroad",
    },
    {
      name: "Engineering Courses Abroad",
      image: coursesGrid3,
      link: "/study-abroad-courses/engineering-courses-abroad",
    },
    {
      name: "Computer Science Courses Abroad",
      image: coursesGrid4,
      link: "/study-abroad-courses/computer-science-courses-abroad",
    },
    {
      name: "Mass Communication",
      image: coursesGrid5,
      link: "/study-abroad-courses/mass-communication",
    },
    {
      name: "Hospitality and Tourism Courses Abroad​",
      image: coursesGrid6,
      link: "/study-abroad-courses/hospitality-and-tourism-management",
    },
    {
      name: "Event Management Courses Abroad",
      image: coursesGrid7,
      link: "/study-abroad-courses/event-management-courses-abroad",
    },
    {
      name: "Data Science Courses Abroad",
      image: coursesGrid8,
      link: "/study-abroad-courses/data-science-courses-abroad",
    },
    {
      name: "Health Science Courses Abroad",
      image: coursesGrid9,
      link: "/study-abroad-courses/health-science-courses-abroad",
    },
    {
      name: "MBA in Ireland",
      image: coursesGrid10,
      link: "/study-abroad-courses/mba-in-ireland",
    },
    {
      name: "Artificial Intelligence Courses Abroad",
      image: coursesGrid11,
      link: "/study-abroad-courses/artificial-intelligence-courses-to-study-abroad",
    },
    {
      name: "Social Work Courses Abroad",
      image: coursesGrid12,
      link: "/study-abroad-courses/study-social-work-abroad",
    },
  ];

  return (
    <div class="education-main-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="education-course education-course-grid">
              <ul class="row">
                {courseList.map((course) => (
                  <li class="col-md-4">
                    <figure>
                      <a href={course?.link}>
                        <img src={course?.image} alt="Guiguidanceplusni" />
                        <i class="fa fa-link"></i>
                      </a>
                    </figure>
                    <div class="education-course-grid-text">
                      <h5>
                        <a href={course?.link}>{course?.name}</a>
                      </h5>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
