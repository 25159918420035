import React from "react";

const ContactUs = () => {
  return (
    <div class="education-main-section">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="education-contact-us">
              <ul>
                <li>
                  <h6>Phone</h6>
                  <span>(+91) 77369 88854</span>
                  <i class="fa fa-phone"></i>
                </li>
                <li>
                  <h6>Email</h6>
                  <a href="mailto:admission@guidanceplusni.com">admission@guidanceplusni.com</a>
                  <i class="fa fa-envelope"></i>
                </li>
                <li>
                  <h6>Location</h6>
                  <span>Bay Pride Mall ,Marine Drive, Kochi, Kerala 682031</span>
                  <i class="fa fa-map-marker"></i>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-9">
            <div class="education-contact-us-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d500141.809410964!2d92.73225!3d11.669029!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x308895a239fed7b1%3A0xf1355d628d112716!2s42%2C%20MA%20Rd%2C%20Phoenix%20Bay%2C%20Port%20Blair%2C%20Andaman%20and%20Nicobar%20Islands%20744102%2C%20India!5e0!3m2!1sen!2sus!4v1718171222123!5m2!1sen!2sus"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                className="contact-map"
              ></iframe>
            </div>
          </div>
          <div class="col-md-12">
            <div class="education-fancy-title">
              <i class="fa fa-graduation-cap"></i>
              <span>get it touch</span>
              <h2>message now</h2>
            </div>
            <div class="education-contact-us-form">
              <form>
                <ul>
                  <li>
                    <input
                      type="text"
                      value="Name"
                      onblur="if(this.value == '') { this.value ='Name'; }"
                      onfocus="if(this.value =='Name') {    this.value = ''; }"
                    />
                    <i class="fa fa-user"></i>
                  </li>
                  <li>
                    <input
                      type="text"
                      value="website"
                      onblur="if(this.value == '') { this.value ='website'; }"
                      onfocus="if(this.value =='website') {    this.value = ''; }"
                    />
                    <i class="fa fa-globe"></i>
                  </li>
                  <li class="message-box">
                    <textarea name="comment" placeholder="Message"></textarea>
                  </li>
                  <li>
                    <label>
                      <input type="submit" value="Send" />
                    </label>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
