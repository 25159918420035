import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Components/Layout";
import Home from "./Components/Home";

import { routes } from "./PageRoutes.js";

import "./App.css";

import "./css/bootstrap.css";
import "./css/font-awesome.css";
import "./css/flaticon.css";
import "./css/slick-slider.css";
import "./css/fancybox.css";
import "./css/style.css";
import "./css/color.css";
import "./css/responsive.css";
import "./css/fancybox.css";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {routes && routes.map((r) => <Route path={r.path} element={r.element} />)}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
