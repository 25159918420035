import React from "react";

import blogImg1 from "../extra-images/blog-thumb-img.jpg";
import blogImg2 from "../extra-images/detail-option-img.jpg";
import blogImg3 from "../extra-images/related-blog-img1.jpg";
import blogImg4 from "../extra-images/related-blog-img2.jpg";
import blogImg5 from "../extra-images/related-blog-img3.jpg";
import blogImg6 from "../extra-images/comment-img1.jpg";
import blogImg7 from "../extra-images/comment-img2.jpg";
import blogImg8 from "../extra-images/comment-img3.jpg";
import blogImg9 from "../extra-images/side-featured-img1.jpg";
import blogImg10 from "../extra-images/side-featured-img2.jpg";
import blogImg11 from "../extra-images/side-featured-img3.jpg";

const BlogDetail = () => {
  return (
    <div class="education-main-section">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <figure class="education-blog-thumb">
              <img src={blogImg1} alt="" />
            </figure>
            <div class="education-thumb-heading">
              <time datetime="2008-02-14 20:00">21 aug</time>
              <h2>Seven UK business schools for intelligent students</h2>
            </div>
            <div class="education-detail-option">
              <figure>
                <img src={blogImg2} alt="" />
                <figcaption>
                  <a href="#">Sarena Silva</a>
                </figcaption>
              </figure>
              <ul class="education-blog-option">
                <li>
                  <i class="fa fa-eye"></i>
                  188
                </li>
                <li>
                  <i class="fa fa-heart-o"></i>
                  211
                </li>
                <li>
                  <i class="fa fa-comments-o"></i>
                  132
                </li>
              </ul>
              <ul class="education-social-network">
                <li>
                  <a href="https://www.facebook.com/" class="fa fa-facebook"></a>
                </li>
                <li>
                  <a href="https://plus.google.com/" class="fa fa-google-plus"></a>
                </li>
                <li>
                  <a href="https://twitter.com/login" class="fa fa-twitter"></a>
                </li>
                <li>
                  <a href="https://pk.linkedin.com/" class="fa fa-linkedin"></a>
                </li>
              </ul>
            </div>
            <div class="education-rich-editor">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac malesuada ante.
                Curabitur lacinia diam tempus tempor consectet Sed vitae dignissim purus, eget
                aliquam libero. Duis et arcu a erat venenatis ornare eget nec urna. Nulla volutpat
                luctus venen Aliquam tellus dui, luctus nec ornare at, aliquet ac nulla. Quisque vit
                ae feugiat eros. Pellentesque tempus tortor nec tellus el.
              </p>
              <p>
                Sed gravida, urna quis tempus sollicitudin, tellus urna suscipit nisl, id rhoncus
                ligula elit condimentum odio. Su spendisse lacinia, risus et porta dignissim, elit
                tellus iaculis tellus, eget efficitur elit magna eu orci. Phasellus tempor
                consectetur magna, at efficit est malesuada ac.
              </p>
              <blockquote>
                <i class="fa fa-quote-right"></i>
                Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                Curae id efficitur condimentum, dui nisl ullamcorper diam, at molestie nulla erat
                gna egestas tempor Lorem ipsum dolor sit.
              </blockquote>
              <p>
                Sed gravida, urna quis tempus sollicitudin, tellus urna suscipit nisl, id rhoncus
                ligula elit condimentum odio. Su risus et porta dignissim, elit tellus iaculis
                tellus, eget efficitur elit magna eu orci. Phasellus tempor consesac est malesuada
                ac. Phasellus non ipsum diam.
              </p>
            </div>
            <div class="education-post-tags">
              <div class="education-tags">
                <span>Tags:</span>
                <a href="/">Course</a>
                <a href="/">WordPress</a>
                <a href="/">Learn</a>
                <a href="/">Education</a>
              </div>
              <a href="/">Share</a>
            </div>
            <div class="education-prenxt-post">
              <ul>
                <li>
                  <div class="education-prev-post">
                    <h3>
                      <a href="/">Lorem ipsum dolor sit amet, consectetu adipiscing elit.</a>
                    </h3>
                    <a href="/" class="education-post-arrow">
                      {" "}
                      Previous Post
                    </a>
                  </div>
                </li>
                <li>
                  <div class="education-next-post">
                    <h3>
                      <a href="/">Lorem ipsum dolor sit amet, consectetu adipiscing elit.</a>
                    </h3>
                    <a href="/" class="education-post-arrow">
                      Next Post{" "}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="education-section-heading">
              <h2>Related Articles</h2>
            </div>
            <div class="education-blog education-related-blog">
              <ul class="row">
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg3} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-related-blog-text">
                    <h5>
                      <a href="/blog-detail">Pellentesque Vitae Tort Sem Pulvinar</a>
                    </h5>
                    <ul class="education-related-option">
                      <li>
                        <a href="/">By John Ryan</a>
                      </li>
                      <li>Aug 21st, 2017</li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg4} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-related-blog-text">
                    <h5>
                      <a href="/blog-detail">Pellentesque Vitae Tort Sem Pulvinar</a>
                    </h5>
                    <ul class="education-related-option">
                      <li>
                        <a href="/">By John Ryan</a>
                      </li>
                      <li>Aug 21st, 2017</li>
                    </ul>
                  </div>
                </li>
                <li class="col-md-4">
                  <figure>
                    <a href="/blog-detail">
                      <img src={blogImg5} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                  </figure>
                  <div class="education-related-blog-text">
                    <h5>
                      <a href="/blog-detail">Pellentesque Vitae Tort Sem Pulvinar</a>
                    </h5>
                    <ul class="education-related-option">
                      <li>
                        <a href="/">By John Ryan</a>
                      </li>
                      <li>Aug 21st, 2017</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div class="comments-area">
              <div class="education-section-heading">
                <h2>Comments</h2>
              </div>
              <ul class="comment-list">
                <li>
                  <div class="thumb-list">
                    <figure>
                      <img alt="" src={blogImg6} />
                    </figure>
                    <div class="text-holder">
                      <a class="comment-reply-link" href="#">
                        Reply <i class="fa fa-share"></i>
                      </a>
                      <h6>Henry Garrick</h6>
                      <time class="post-date" datetime="2008-02-14 20:00">
                        November 11, 2017{" "}
                      </time>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et elementum
                        nulla, eu placerat feis. Eti dunt orci lacus, id varius dolor fermentum sit
                        amet. Morbi consectetur ante odio, at vestibulum nib id. In porttitor nunc
                        a.
                      </p>
                    </div>
                  </div>
                  <ul class="children">
                    <li>
                      <div class="thumb-list">
                        <figure>
                          <img alt="" src={blogImg7} />
                        </figure>
                        <div class="text-holder">
                          <h6>Mark Bolt</h6>
                          <time class="post-date" datetime="2008-02-14 20:00">
                            August 21, 2017{" "}
                          </time>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et
                            elementum nulla, eu pla dunt orci lacus, id varius dolor fermentum sit
                            amet. Morbi consectetur ante odio, at ve id. In porttitor nunc a.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                <li>
                  <div class="thumb-list">
                    <figure>
                      <img alt="" src={blogImg8} />
                    </figure>
                    <div class="text-holder">
                      <a class="comment-reply-link" href="#">
                        Reply <i class="fa fa-share"></i>
                      </a>
                      <h6>Ian Strauss</h6>
                      <time class="post-date" datetime="2008-02-14 20:00">
                        November 11, 2017{" "}
                      </time>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et elementum
                        nulla, eu placerat feis. Eti dunt orci lacus, id varius dolor fermentum sit
                        amet. Morbi consectetur ante odio, at vestibulum nib id. In porttitor nunc
                        a.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="comment-respond">
                <div class="education-section-heading">
                  <h2>Leave a Comments</h2>
                </div>
                <form>
                  <p>
                    <input
                      type="text"
                      value="Name"
                      onblur="if(this.value == '') { this.value ='Name'; }"
                      onfocus="if(this.value =='Name') { this.value = ''; }"
                    />
                    <i class="fa fa-user"></i>
                  </p>
                  <p>
                    <input
                      type="text"
                      value="Website"
                      onblur="if(this.value == '') { this.value ='Website'; }"
                      onfocus="if(this.value =='Website') { this.value = ''; }"
                    />
                    <i class="fa fa-globe"></i>
                  </p>
                  <p class="education-full-form">
                    <textarea
                      name="comment"
                      placeholder="Message"
                      class="commenttextarea"
                    ></textarea>
                    <i class="fa fa-comments"></i>
                  </p>
                  <p class="form-submit">
                    <label>
                      <input value="Send" type="submit" />
                    </label>{" "}
                    <input name="comment_post_ID" value="99" id="comment_post_ID" type="hidden" />
                  </p>
                </form>
              </div>
            </div>
          </div>
          <aside class="col-md-3 education-sidebar">
            <div class="widget widget_search">
              <form>
                <input
                  type="text"
                  value="Search"
                  onblur="if(this.value == '') { this.value ='Search'; }"
                  onfocus="if(this.value =='Search') { this.value = ''; }"
                />
                <label>
                  <input type="submit" value="" />
                </label>
              </form>
            </div>

            <div class="education-widget-heading">
              <h2>Cetagories</h2>
            </div>
            <div class="widget widget_cetagories">
              <ul>
                <li>
                  <a href="/">
                    Web Design<span>( 12 )</span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    Graphic Design<span>( 09 )</span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    Courses<span>( 07 )</span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    Blogs<span>( 11 )</span>
                  </a>
                </li>
                <li>
                  <a href="/">
                    Music<span>( 10 )</span>
                  </a>
                </li>
              </ul>
            </div>

            <div class="education-widget-heading">
              <h2>Featured Courses</h2>
            </div>
            <div class="widget widget_featured_courses">
              <ul>
                <li>
                  <figure>
                    <a href="/study-abroad-courses">
                      <img src={blogImg9} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                    <figcaption>
                      <h6>
                        <a href="/study-abroad-courses">Lorem ipsum dolor si tamet cons</a>
                      </h6>
                      <a href="/study-abroad-courses">
                        <i class="fa fa-user"></i>Prof John Martyn
                      </a>
                      <div class="star-rating">
                        <span class="star-rating-box width54"></span>
                      </div>
                      <small>( 12 Reviews )</small>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a href="/study-abroad-courses">
                      <img src={blogImg10} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                    <figcaption>
                      <h6>
                        <a href="/study-abroad-courses">Lorem ipsum dolor si tamet cons</a>
                      </h6>
                      <a href="/study-abroad-courses">
                        <i class="fa fa-user"></i>Prof John Martyn
                      </a>
                      <div class="star-rating">
                        <span class="star-rating-box width54"></span>
                      </div>
                      <small>( 12 Reviews )</small>
                    </figcaption>
                  </figure>
                </li>
                <li>
                  <figure>
                    <a href="/study-abroad-courses">
                      <img src={blogImg11} alt="" />
                      <i class="fa fa-link"></i>
                    </a>
                    <figcaption>
                      <h6>
                        <a href="/study-abroad-courses">Lorem ipsum dolor si tamet cons</a>
                      </h6>
                      <a href="/study-abroad-courses">
                        <i class="fa fa-user"></i>Prof John Martyn
                      </a>
                      <div class="star-rating">
                        <span class="star-rating-box width54"></span>
                      </div>
                      <small>( 12 Reviews )</small>
                    </figcaption>
                  </figure>
                </li>
              </ul>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
