import React from "react";

import courseImg1 from "../extra-images/courses-modren-img1.jpg";
import commentOption from "../extra-images/comment-option-img.jpg";
import coursesImg2 from "../extra-images/courses-modren-img2.jpg";
import coursesImg3 from "../extra-images/courses-modren-img3.jpg";
import testimonialimg from "../extra-images/testimonial-fig-img.jpg";
import eventImg1 from "../extra-images/event-medium-img1.jpg";
import eventImg2 from "../extra-images/event-medium-img2.jpg";
import eventImg3 from "../extra-images/event-medium-img3.jpg";
import eventImg4 from "../extra-images/event-medium-img4.jpg";
import modernGallery1 from "../extra-images/modern-gallery-img1.jpg";
import modernGallery2 from "../extra-images/modern-gallery-img2.jpg";
import modernGallery3 from "../extra-images/modern-gallery-img3.jpg";
import modernGallery4 from "../extra-images/modern-gallery-img4.jpg";
import modernGallery5 from "../extra-images/modern-gallery-img5.jpg";
import modernGallery6 from "../extra-images/modern-gallery-img6.jpg";

const Home = () => {
  return (
    <>
      <div class="education-main-section">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="education-fancy-title">
                <i class="fa fa-graduation-cap"></i>
                <span>what we provide</span>
                <h2>Our Services</h2>
              </div>
              <div class="education-service education-service-grid">
                <ul class="row">
                  <li class="col-md-3">
                    <div class="education-service-grid-wrap">
                      <i class=" icon-old-fashion-briefcase"></i>
                      <h5>
                        <a href="/">Business</a>
                      </h5>
                      <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                      <a href="/" class="education-readmore-btn">
                        Read More <i class="fa fa-arrow-circle-o-right"></i>
                      </a>
                    </div>
                  </li>
                  <li class="col-md-3">
                    <div class="education-service-grid-wrap">
                      <i class="icon-calculate"></i>
                      <h5>
                        <a href="/">Economics</a>
                      </h5>
                      <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                      <a href="/" class="education-readmore-btn">
                        Read More <i class="fa fa-arrow-circle-o-right"></i>
                      </a>
                    </div>
                  </li>
                  <li class="col-md-3">
                    <div class="education-service-grid-wrap">
                      <i class="icon-flask-outline"></i>
                      <h5>
                        <a href="/">Science</a>
                      </h5>
                      <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                      <a href="/" class="education-readmore-btn">
                        Read More <i class="fa fa-arrow-circle-o-right"></i>
                      </a>
                    </div>
                  </li>
                  <li class="col-md-3">
                    <div class="education-service-grid-wrap">
                      <i class="icon-calculate"></i>
                      <h5>
                        <a href="/">Development</a>
                      </h5>
                      <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                      <a href="/" class="education-readmore-btn">
                        Read More <i class="fa fa-arrow-circle-o-right"></i>
                      </a>
                    </div>
                  </li>
                  <li class="col-md-3">
                    <div class="education-service-grid-wrap">
                      <i class="icon-pencil-and-ruler-cross-of-school-materials"></i>
                      <h5>
                        <a href="/">Maths</a>
                      </h5>
                      <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                      <a href="/" class="education-readmore-btn">
                        Read More <i class="fa fa-arrow-circle-o-right"></i>
                      </a>
                    </div>
                  </li>
                  <li class="col-md-3">
                    <div class="education-service-grid-wrap">
                      <i class="icon-computer-mouse"></i>
                      <h5>
                        <a href="/">Web Designing</a>
                      </h5>
                      <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                      <a href="/" class="education-readmore-btn">
                        Read More <i class="fa fa-arrow-circle-o-right"></i>
                      </a>
                    </div>
                  </li>
                  <li class="col-md-3">
                    <div class="education-service-grid-wrap">
                      <i class="icon-molecule"></i>
                      <h5>
                        <a href="/">Biology</a>
                      </h5>
                      <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                      <a href="/" class="education-readmore-btn">
                        Read More <i class="fa fa-arrow-circle-o-right"></i>
                      </a>
                    </div>
                  </li>
                  <li class="col-md-3">
                    <div class="education-service-grid-wrap">
                      <i class=" icon-laptop2"></i>
                      <h5>
                        <a href="/">Computing</a>
                      </h5>
                      <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                      <a href="/" class="education-readmore-btn">
                        Read More <i class="fa fa-arrow-circle-o-right"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="education-main-section education-modren-coursefull">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="education-fancy-title">
                <i class="fa fa-graduation-cap"></i>
                <span>Choose Your Course</span>
                <h2>Top Courses</h2>
              </div>
              <div class="education-course education-modren-course">
                <ul class="row">
                  <li class="col-md-4">
                    <figure>
                      <a href="/">
                        <img src={courseImg1} alt="Guiguidanceplusni" />
                        <i class="fa fa-link"></i>
                      </a>
                      <div class="star-rating">
                        <span class="star-rating-box" style={{ width: "55%" }}></span>
                      </div>
                      <span>$170</span>
                    </figure>
                    <div class="education-grid-wrap">
                      <div class="education-modren-course-text">
                        <h5>
                          <a href="/">Coral Fashion Designer</a>
                        </h5>
                        <ul class="education-course-option">
                          <li>
                            <a href="/">By John Ryan</a>
                          </li>
                          <li>Aug 21st, 2017</li>
                        </ul>
                        <p>
                          Lorem ipsum dolor sit amet, conseetur adiis cing elit. Praesent feugiat ur
                          non ipsu mu malesuada. Donec non ex faucibus.
                        </p>
                      </div>
                      <div class="education-comment-option">
                        <figure>
                          <img src={commentOption} alt="Guiguidanceplusni" />
                        </figure>
                        <a href="/">Jenny Harris</a>
                        <ul class="education-comment">
                          <li>
                            <a href="/">
                              <i class="fa fa-comments"></i>21
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <i class="fa fa-eye"></i>12
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <i class="fa fa-share-alt"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="col-md-4">
                    <figure>
                      <a href="/">
                        <img src={coursesImg2} alt="Guiguidanceplusni" />
                        <i class="fa fa-link"></i>
                      </a>
                      <div class="star-rating">
                        <span class="star-rating-box" style={{ width: "55%" }}></span>
                      </div>
                      <span>$170</span>
                    </figure>
                    <div class="education-grid-wrap">
                      <div class="education-modren-course-text">
                        <h5>
                          <a href="/">Coral Fashion Designer</a>
                        </h5>
                        <ul class="education-course-option">
                          <li>
                            <a href="/">By John Ryan</a>
                          </li>
                          <li>Aug 21st, 2017</li>
                        </ul>
                        <p>
                          Lorem ipsum dolor sit amet, conseetur adiis cing elit. Praesent feugiat ur
                          non ipsu mu malesuada. Donec non ex faucibus.
                        </p>
                      </div>
                      <div class="education-comment-option">
                        <figure>
                          <img src={commentOption} alt="Guiguidanceplusni" />
                        </figure>
                        <a href="/">Jenny Harris</a>
                        <ul class="education-comment">
                          <li>
                            <a href="/">
                              <i class="fa fa-comments"></i>21
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <i class="fa fa-eye"></i>12
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <i class="fa fa-share-alt"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li class="col-md-4">
                    <figure>
                      <a href="/">
                        <img src={coursesImg3} alt="Guiguidanceplusni" />
                        <i class="fa fa-link"></i>
                      </a>
                      <div class="star-rating">
                        <span class="star-rating-box" style={{ width: "55%" }}></span>
                      </div>
                      <span>$170</span>
                    </figure>
                    <div class="education-grid-wrap">
                      <div class="education-modren-course-text">
                        <h5>
                          <a href="/">Coral Fashion Designer</a>
                        </h5>
                        <ul class="education-course-option">
                          <li>
                            <a href="/">By John Ryan</a>
                          </li>
                          <li>Aug 21st, 2017</li>
                        </ul>
                        <p>
                          Lorem ipsum dolor sit amet, conseetur adiis cing elit. Praesent feugiat ur
                          non ipsu mu malesuada. Donec non ex faucibus.
                        </p>
                      </div>
                      <div class="education-comment-option">
                        <figure>
                          <img src={commentOption} alt="Guiguidanceplusni" />
                        </figure>
                        <a href="/">Jenny Harris</a>
                        <ul class="education-comment">
                          <li>
                            <a href="/">
                              <i class="fa fa-comments"></i>21
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <i class="fa fa-eye"></i>12
                            </a>
                          </li>
                          <li>
                            <a href="/">
                              <i class="fa fa-share-alt"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="education-main-section education-testimonial-full">
        <span class="education-shape-one"></span>
        <span class="education-shape-two"></span>
        <span class="testimonial-transparent"></span>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="education-testimonial">
                <i class="fa fa-quote-right"></i>
                <div class="clearfix"></div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat urna non
                  ipsum maximus malesuada. Done c non ex faucibus, fringilla tellus eget, Proin
                  eleifend velit ve maximus arcu.et eros nisl. Morbi faucibus nibh ligula, et cursus
                  nibh elementum vel.
                </p>
                <div class="education-testimonial-figure">
                  <figure>
                    <img src={testimonialimg} alt="Guiguidanceplusni" />
                  </figure>
                  <section>
                    <h6>
                      <a href="/">Donald Simpson</a>
                    </h6>
                    <span>Founder & SEO</span>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="education-main-section education-event-mediumfull">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="education-fancy-title">
                <i class="fa fa-graduation-cap"></i>
                <span>Events of learn</span>
                <h2>Upcoming Events</h2>
              </div>
              <div class="education-event education-event-medium">
                <ul class="row">
                  <li class="col-md-6">
                    <figure>
                      <a href="/">
                        <img src={eventImg1} alt="Guiguidanceplusni" />
                        <i class="fa fa-link"></i>
                      </a>
                    </figure>
                    <div class="education-event-medium-text">
                      <h5>
                        <a href="/">WordPress And The Future Of Internet</a>
                      </h5>
                      <ul class="education-event-option">
                        <li>
                          <i class="fa fa-calendar-o"></i> April 16, 2017
                        </li>
                        <li>
                          <i class="fa fa-clock-o"></i> 6:30 AM - 5:00 PM
                        </li>
                        <li>
                          <i class="fa fa-map-marker"></i> 2925 Swick Hill Street, Charlotte
                        </li>
                      </ul>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat.
                      </p>
                      <a href="/" class="education-simple-btn">
                        Learn More
                      </a>
                    </div>
                  </li>
                  <li class="col-md-6">
                    <figure>
                      <a href="/">
                        <img src={eventImg2} alt="Guiguidanceplusni" />
                        <i class="fa fa-link"></i>
                      </a>
                    </figure>
                    <div class="education-event-medium-text">
                      <h5>
                        <a href="/">Guest Interview will Occur Goon</a>
                      </h5>
                      <ul class="education-event-option">
                        <li>
                          <i class="fa fa-calendar-o"></i> April 16, 2017
                        </li>
                        <li>
                          <i class="fa fa-clock-o"></i> 6:30 AM - 5:00 PM
                        </li>
                        <li>
                          <i class="fa fa-map-marker"></i> 2925 Swick Hill Street, Charlotte
                        </li>
                      </ul>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat.
                      </p>
                      <a href="/" class="education-simple-btn">
                        Learn More
                      </a>
                    </div>
                  </li>
                  <li class="col-md-6">
                    <figure>
                      <a href="/">
                        <img src={eventImg3} alt="Guiguidanceplusni" />
                        <i class="fa fa-link"></i>
                      </a>
                    </figure>
                    <div class="education-event-medium-text">
                      <h5>
                        <a href="/">Summer Course Start From 1st June</a>
                      </h5>
                      <ul class="education-event-option">
                        <li>
                          <i class="fa fa-calendar-o"></i> April 16, 2017
                        </li>
                        <li>
                          <i class="fa fa-clock-o"></i> 6:30 AM - 5:00 PM
                        </li>
                        <li>
                          <i class="fa fa-map-marker"></i> 2925 Swick Hill Street, Charlotte
                        </li>
                      </ul>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat.
                      </p>
                      <a href="/" class="education-simple-btn">
                        Learn More
                      </a>
                    </div>
                  </li>
                  <li class="col-md-6">
                    <figure>
                      <a href="/">
                        <img src={eventImg4} alt="Guiguidanceplusni" />
                        <i class="fa fa-link"></i>
                      </a>
                    </figure>
                    <div class="education-event-medium-text">
                      <h5>
                        <a href="/">WordPress And The Future Of Internet</a>
                      </h5>
                      <ul class="education-event-option">
                        <li>
                          <i class="fa fa-calendar-o"></i> April 16, 2017
                        </li>
                        <li>
                          <i class="fa fa-clock-o"></i> 6:30 AM - 5:00 PM
                        </li>
                        <li>
                          <i class="fa fa-map-marker"></i> 2925 Swick Hill Street, Charlotte
                        </li>
                      </ul>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent feugiat.
                      </p>
                      <a href="/" class="education-simple-btn">
                        Learn More
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="education-main-section education-counter-img">
        <span class="counter-transparent"></span>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="education-counter">
                <ul class="row">
                  <li class="col-md-3">
                    <i class="icon-old-fashion-briefcase"></i>
                    <h4 id="word-count1">10+</h4>
                    <span>Years of experience</span>
                  </li>
                  <li class="col-md-3">
                    <i class=" icon-people3"></i>
                    <h4 id="word-count2">5,000+</h4>
                    <span>Students Counseled</span>
                  </li>
                  <li class="col-md-3">
                    <i class=" icon-bell"></i>
                    <h4 id="word-count3">1,000+</h4>
                    <span>University options</span>
                  </li>
                  <li class="col-md-3">
                    <i class="icon-earth"></i>
                    <h4 id="word-count4">1,000+</h4>
                    <span>Students per year</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="education-main-section education-gallery-modernfull">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="education-fancy-title">
                <i class="fa fa-graduation-cap"></i>
                <span>Events of learn</span>
                <h2>our Gallery</h2>
              </div>
              <div class="education-gallery education-modern-gallery">
                <ul>
                  <li>
                    <figure>
                      <a data-fancybox-group="group" href={modernGallery1} class="fancybox">
                        <img src={modernGallery1} alt="Guiguidanceplusni" />
                        <i class="icon-enlarge"></i>
                      </a>
                      <figcaption>
                        <span>12 Photos</span>
                        <h5>
                          <a href="/">Ut at dapibus quam</a>
                          <small>Lorem ipsum dolor</small>
                        </h5>
                      </figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <a data-fancybox-group="group" href={modernGallery2} class="fancybox">
                        <img src={modernGallery2} alt="Guiguidanceplusni" />
                        <i class="icon-enlarge"></i>
                      </a>
                      <figcaption>
                        <span>12 Photos</span>
                        <h5>
                          <a href="/">Ut at dapibus quam</a>
                          <small>Lorem ipsum dolor</small>
                        </h5>
                      </figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <a data-fancybox-group="group" href={modernGallery3} class="fancybox">
                        <img src={modernGallery3} alt="Guiguidanceplusni" />
                        <i class="icon-enlarge"></i>
                      </a>
                      <figcaption>
                        <span>12 Photos</span>
                        <h5>
                          <a href="/">Ut at dapibus quam</a>
                          <small>Lorem ipsum dolor</small>
                        </h5>
                      </figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <a data-fancybox-group="group" href={modernGallery4} class="fancybox">
                        <img src={modernGallery4} alt="Guiguidanceplusni" />
                        <i class="icon-enlarge"></i>
                      </a>
                      <figcaption>
                        <span>12 Photos</span>
                        <h5>
                          <a href="/">Ut at dapibus quam</a>
                          <small>Lorem ipsum dolor</small>
                        </h5>
                      </figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <a data-fancybox-group="group" href={modernGallery5} class="fancybox">
                        <img src={modernGallery5} alt="Guiguidanceplusni" />
                        <i class="icon-enlarge"></i>
                      </a>
                      <figcaption>
                        <span>12 Photos</span>
                        <h5>
                          <a href="/">Ut at dapibus quam</a>
                          <small>Lorem ipsum dolor</small>
                        </h5>
                      </figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <a data-fancybox-group="group" href={modernGallery6} class="fancybox">
                        <img src={modernGallery6} alt="Guiguidanceplusni" />
                        <i class="icon-enlarge"></i>
                      </a>
                      <figcaption>
                        <span>12 Photos</span>
                        <h5>
                          <a href="/">Ut at dapibus quam</a>
                          <small>Lorem ipsum dolor</small>
                        </h5>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
