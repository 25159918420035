import React from "react";

import aboutUsImage1 from "../extra-images/aboutus-image-1.jpg";
import postImg from "../extra-images/post-text-img.png";
import itemDetail1 from "../extra-images/team-detail-img1.jpg";
import itemDetail2 from "../extra-images/team-detail-img2.jpg";
import itemDetail3 from "../extra-images/team-detail-img3.jpg";
import itemDetail4 from "../extra-images/team-detail-img4.jpg";

const AboutUs = () => {
  return (
    <>
      <div class="education-main-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="education-about-services">
                <span>About Our University</span>
                <h2>Well Come To Learn Edu </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et eletum nulla, eu
                  placerat felis. Etiam tincidunt orci lacus, id varius dolor fermum sit amet. Morbi
                  consectetur ante odio, at vestibulum nibh fringilla id.{" "}
                </p>
                <div class="education-service education-service-grid">
                  <ul class="row">
                    <li class="col-md-6">
                      <div class="education-service-grid-wrap">
                        <i class=" icon-old-fashion-briefcase"></i>
                        <h5>
                          <a href="service-detail.html">Business</a>
                        </h5>
                        <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                        <a href="service-detail.html" class="education-readmore-btn">
                          Read More <i class="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li class="col-md-6">
                      <div class="education-service-grid-wrap">
                        <i class="icon-calculate"></i>
                        <h5>
                          <a href="service-detail.html">Economics</a>
                        </h5>
                        <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                        <a href="service-detail.html" class="education-readmore-btn">
                          Read More <i class="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li class="col-md-6">
                      <div class="education-service-grid-wrap">
                        <i class="icon-flask-outline"></i>
                        <h5>
                          <a href="service-detail.html">Science</a>
                        </h5>
                        <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                        <a href="service-detail.html" class="education-readmore-btn">
                          Read More <i class="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                    <li class="col-md-6">
                      <div class="education-service-grid-wrap">
                        <i class="icon-calculate"></i>
                        <h5>
                          <a href="service-detail.html">Development</a>
                        </h5>
                        <p>Lorem ipsum dolor sit amet, con ctetur adipiscing elit. Praese</p>
                        <a href="service-detail.html" class="education-readmore-btn">
                          Read More <i class="fa fa-arrow-circle-o-right"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <img src={aboutUsImage1} alt="Guiguidanceplusni" class="education-about-image" />
            </div>
          </div>
        </div>
      </div>

      <div class="education-main-section education-section-postfull">
        <span class="post-transparent"></span>
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <div class="education-post-image">
                <img src={postImg} alt="Guiguidanceplusni" />
              </div>
            </div>
            <div class="col-md-7">
              <div class="education-post-text">
                <h3>Aliquam in elit vel eros tincidunt</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et elementum nulla,
                  eu placerat felis. Etiam tincidunt orci lacus, id varius dolor fermentum sit amet.
                </p>
                <ul class="education-post-list">
                  <li>
                    <i class="fa fa-bar-chart"></i> Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit.
                  </li>
                  <li>
                    <i class="fa fa-calendar-o"></i> Pellentesque vitae tortor vel sem pulvinar
                    euismod.
                  </li>
                  <li>
                    <i class="fa fa-futbol-o"></i> Nunc ut volutpat ex. Morbi risus elit, mattis in
                    mi nec, mollis blandit erat.
                  </li>
                  <li>
                    <i class="fa fa-book"></i> In eget aliquam odio. Quisque sodales eros ac dui
                    tristique
                  </li>
                </ul>
                <a href="404.html" class="education-post-btn">
                  Get in Touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="education-main-section education-about-teamfull">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="education-fancy-title">
                <i class="fa fa-graduation-cap"></i>
                <span>what we provide</span>
                <h2>Our Team</h2>
              </div>
              <div class="education-team education-team-grid">
                <ul class="row">
                  <li class="col-md-3">
                    <figure>
                      <a href="team-detail.html">
                        <img src={itemDetail1} alt="Guiguidanceplusni" />
                      </a>
                      <figcaption>
                        <div class="education-team-grid-text">
                          <h5>
                            <a href="team-detail.html">Sarena Jordan</a>
                          </h5>
                          <span>Web Developer</span>
                          <p>
                            Lorem ipsum dolor sit ae t, consectetur adipiscin elit. Sed et elementu
                            nulla eu.
                          </p>
                          <ul class="education-team-social">
                            <li>
                              <a href="https://www.facebook.com/" class="fa fa-facebook-square"></a>
                            </li>
                            <li>
                              <a href="https://twitter.com/login" class="fa fa-twitter-square"></a>
                            </li>
                            <li>
                              <a href="https://pk.linkedin.com/" class="fa fa-linkedin-square"></a>
                            </li>
                            <li>
                              <a
                                href="https://plus.google.com/"
                                class="fa fa-google-plus-square"
                              ></a>
                            </li>
                          </ul>
                        </div>
                        <div class="education-team-grid-info">
                          <h5>
                            <a href="team-detail.html">Sarena Jordan</a>
                          </h5>
                          <span>Web Developer</span>
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                  <li class="col-md-3">
                    <figure>
                      <a href="team-detail.html">
                        <img src={itemDetail2} alt="Guiguidanceplusni" />
                      </a>
                      <figcaption>
                        <div class="education-team-grid-text">
                          <h5>
                            <a href="team-detail.html">Johnny Cash</a>
                          </h5>
                          <span>Web Developer</span>
                          <p>
                            Lorem ipsum dolor sit ae t, consectetur adipiscin elit. Sed et elementu
                            nulla eu.
                          </p>
                          <ul class="education-team-social">
                            <li>
                              <a href="https://www.facebook.com/" class="fa fa-facebook-square"></a>
                            </li>
                            <li>
                              <a href="https://twitter.com/login" class="fa fa-twitter-square"></a>
                            </li>
                            <li>
                              <a href="https://pk.linkedin.com/" class="fa fa-linkedin-square"></a>
                            </li>
                            <li>
                              <a
                                href="https://plus.google.com/"
                                class="fa fa-google-plus-square"
                              ></a>
                            </li>
                          </ul>
                        </div>
                        <div class="education-team-grid-info">
                          <h5>
                            <a href="team-detail.html">Johnny Cash</a>
                          </h5>
                          <span>Web Developer</span>
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                  <li class="col-md-3">
                    <figure>
                      <a href="team-detail.html">
                        <img src={itemDetail3} alt="Guiguidanceplusni" />
                      </a>
                      <figcaption>
                        <div class="education-team-grid-text">
                          <h5>
                            <a href="team-detail.html">Darren Clarke</a>
                          </h5>
                          <span>Web Developer</span>
                          <p>
                            Lorem ipsum dolor sit ae t, consectetur adipiscin elit. Sed et elementu
                            nulla eu.
                          </p>
                          <ul class="education-team-social">
                            <li>
                              <a href="https://www.facebook.com/" class="fa fa-facebook-square"></a>
                            </li>
                            <li>
                              <a href="https://twitter.com/login" class="fa fa-twitter-square"></a>
                            </li>
                            <li>
                              <a href="https://pk.linkedin.com/" class="fa fa-linkedin-square"></a>
                            </li>
                            <li>
                              <a
                                href="https://plus.google.com/"
                                class="fa fa-google-plus-square"
                              ></a>
                            </li>
                          </ul>
                        </div>
                        <div class="education-team-grid-info">
                          <h5>
                            <a href="team-detail.html">Darren Clarke</a>
                          </h5>
                          <span>Web Developer</span>
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                  <li class="col-md-3">
                    <figure>
                      <a href="team-detail.html">
                        <img src={itemDetail4} alt="Guiguidanceplusni" />
                      </a>
                      <figcaption>
                        <div class="education-team-grid-text">
                          <h5>
                            <a href="team-detail.html">Marry Mike</a>
                          </h5>
                          <span>Web Developer</span>
                          <p>
                            Lorem ipsum dolor sit ae t, consectetur adipiscin elit. Sed et elementu
                            nulla eu.
                          </p>
                          <ul class="education-team-social">
                            <li>
                              <a href="https://www.facebook.com/" class="fa fa-facebook-square"></a>
                            </li>
                            <li>
                              <a href="https://twitter.com/login" class="fa fa-twitter-square"></a>
                            </li>
                            <li>
                              <a href="https://pk.linkedin.com/" class="fa fa-linkedin-square"></a>
                            </li>
                            <li>
                              <a
                                href="https://plus.google.com/"
                                class="fa fa-google-plus-square"
                              ></a>
                            </li>
                          </ul>
                        </div>
                        <div class="education-team-grid-info">
                          <h5>
                            <a href="team-detail.html">Marry Mike</a>
                          </h5>
                          <span>Web Developer</span>
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
